<template>
  <section class="library-form-section">
    <v-container>
      <!-- Form to add/edit library -->
      <v-card class="form-card">
      <v-card-title>Ajouter une librairie</v-card-title>
      <v-card-text>
      <v-form v-model="valid" ref="form" @submit.prevent="submitForm" >
        <v-row>
          <v-col >
            <v-text-field
              v-model="library.name"
              label="Nom de librairie"
              outlined
              required
              class="form-field"
              :rules="[v => !!v || 'Nom est requis']"
            ></v-text-field>
          </v-col>
          <v-col>
  <v-text-field
    v-model="searchEmail"
    label="Email"
    outlined
    @input="searchUsersByEmail"
    :rules="[v => !!v || 'Email est requis']"
    :readonly="loading"
  ></v-text-field>

  <!-- Dropdown for Search Results -->
  <v-list v-if="userEmails.length" class="search-results-list">
    <v-list-item-group>
      <v-list-item
        v-for="user in userEmails"
        :key="user.email"
        @click="selectUser(user)"
        class="search-list-item"
      >
        <v-list-item-content>
          <v-list-item-title>{{ user.email }}</v-list-item-title>
          <v-list-item-subtitle>{{ user.name }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</v-col>
          <v-col>
            <v-text-field
              v-model="library.numTel"
              label="Numéro de téléphone"
              outlined
              required
              class="form-field"
              
            ></v-text-field>
          </v-col>
        </v-row>
       
        <v-row>
          <v-col >
            <v-text-field
              v-model="library.address"
              label="Addresse"
              outlined
              required
              class="form-field"
              :rules="[v => !!v || 'Adresse est requis']"
            ></v-text-field>
          </v-col>
          <v-col >
            <v-text-field
              v-model="library.postalCode"
              label="Code Postal"
              outlined
              required
              class="form-field"
              :rules="[v => !!v || 'Code Postal est requis']"
               @input="onCodePostalChange"
            ></v-text-field>
          </v-col>
          <v-col >
  <v-autocomplete
    v-model="library.city"
    :items="cities"
    item-value="label"  
    item-text="label"
    label="Ville"
    outlined
    @change="onCityChange"
    required
    :rules="[v => !!v || 'Ville est requis']"
  ></v-autocomplete>
</v-col>
        </v-row>
        
<v-row>
  <v-col>
            <v-text-field
              v-model="library.limitBook"
              label="limite de livre"
              outlined
              required
              class="form-field"
              
            ></v-text-field>
          </v-col>
</v-row>
        <v-row v-if="library.name">
          <!-- Primary Image Upload -->
          <v-col cols="12" sm="6">
            <vue-upload-multiple-image
              :idUpload="imagesId"
              v-model="images"
              :data-images="images"
              name="images"
              drag-text="Please add an image!"
              browse-text="Browse image"
              primary-text="Primary image"
              mark-is-primary-text="Set default image"
              :min-image="1"
              :max-image="2"

              :showEdit="false"

              :multiple="false"
              :loading="load"
              popup-text="Description default image"
              drop-text="Drag and drop"
              @upload-success="uploadImageSuccess"
              @before-remove="beforeRemove"
              @images-updated="handleImagesUpdated"
            ></vue-upload-multiple-image>
            <v-progress-linear
              v-if="showLoading && uploadPercentage < 100"
              :value="uploadPercentage"
              height="15"
              color="primary"
              class="progress-bar"
            >
              <strong>{{ Math.ceil(uploadPercentage) }}%</strong>
            </v-progress-linear>
          </v-col>

          <!-- Banner Image Upload -->
          <v-col cols="12" sm="6">
            <vue-upload-multiple-image
              :idUpload="imagesIdBanner"
              v-model="bannerImage"
              :data-images="bannerImage"
              name="bannerImage"
              drag-text="Please add a banner image!"
              browse-text="Browse banner image"
              primary-text="Banner image"
              :min-image="1"
              :max-image="2"

              :showEdit="false"
              :multiple="false"
              :loading="load"
              popup-text="Description banner image"
              drop-text="Drag and drop"
              @upload-success="uploadImageSuccessBanner"
              @before-remove="beforeRemoveBanner"
            ></vue-upload-multiple-image>
            <v-progress-linear
              v-if="showLoadingBanner && uploadPercentageBanner < 100"
              :value="uploadPercentageBanner"
              height="15"
              color="primary"
              class="progress-bar"
            >
              <strong>{{ Math.ceil(uploadPercentageBanner) }}%</strong>
            </v-progress-linear>
          </v-col>
        </v-row>
      <v-row>
        <v-btn type="submit" color="info" :loading="loading" class="submit-btn" :disabled="!isFormComplete">
            {{ isEditing ? 'Modifier' : 'Ajouter' }}
          </v-btn>
      </v-row>
      </v-form>
      </v-card-text>
      </v-card>
      <v-divider class="my-4"></v-divider>
      <!-- Display data table of libraries -->
      <v-card >
        <v-card-title>Liste des Libraries</v-card-title>
      <v-text-field
        style="padding:15px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
        :search="search"
      ></v-text-field>
      <v-data-table
        :headers="headers"
        :items="libraries"
        item-key="id"
        :search="search"
        :loading="loading"
        :no-data-text="noDataText"
        class="library-data-table"
      >
        <!-- <template v-slot:item.fileUrl="{ item }">
          <v-img :src="item.fileUrl" width="100px" height="100px" style="border-radius: 10px;margin:10px;box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);"></v-img>
        </template> -->
        <template v-slot:item.fileUrl="{ item }">
          <v-img
            style="margin: 10px"
            :src="item.fileDBId ? getImageUrl(item.fileDBId) : item.fileUrl"
            width="65"
            height="65"
          />
          <v-divider></v-divider>
        </template>
        <template v-slot:item.edit="{ item }">
          <v-icon class="icon-edit" @click="editLibrary(item)">mdi-pencil</v-icon>
        </template>
        <template v-slot:item.delete="{ item }">
          <v-icon class="icon-delete" @click="deleteLibrary(item.id)">mdi-delete</v-icon>
        </template>
        <template v-slot:item.detail="{ item }">
          <v-icon class="icon-view" @click="viewLibraryDetails(item.id)">mdi-book-open-page-variant</v-icon>
        </template>
      </v-data-table>
      </v-card>
      <!-- Confirmation Dialog -->
      <v-row justify="center">
      <v-dialog v-model="confirmDeleteDialog" max-width="530">
        <v-card>
          <v-card-title class="text-h6">
            Etes vous sur de vouloir supprimer cette librairie?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1 error"
              text
             @click="cancelDelete"
            >
              Annuler
            </v-btn>

            <v-btn color="info darken-1 " text @click="confirmDelete">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    </v-container>
  </section>
</template>



<script>
import axios from 'axios';
import store from '../../../store/image';
import VueUploadMultipleImage from "vue-upload-multiple-image";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import Constant from "@/utils/constants";
import citiesData from '../../../cities.json';
import { email } from 'vuelidate/lib/validators';

export default {
  components: { 
      VueUploadMultipleImage 
  },
  computed: {
    isFormComplete() {
  const baseValidation =
    (this.library.name || '').trim() !== '' &&
    (this.library.email || '').trim() !== '' &&
    (this.library.numTel || '').trim() !== '' &&
    // (this.library.limitBook || '').trim() !== '' &&
    (this.library.address || '').trim() !== '' &&
    (this.library.postalCode || '').trim() !== '' &&
    (this.library.city || '').trim() !== '';

  // Si on est en mode "ajouter" (pas d'images téléchargées)
  // if (this.images.length === 0 && this.bannerImage.length === 0) {
  //   return baseValidation && this.showLoading && this.showLoadingBanner;
  // }

  // Si on est en mode "modifier" (images déjà présentes ou non vide), on valide uniquement les autres champs
  return baseValidation;
}

  },

  data() {
    return {
        libraries: [],
        cities: citiesData, // Use the imported data directly
        selectedCity: null, // For storing the selected city
        selectedCityDetails: null,
        searchEmail: '', // Email input
        userEmails: [],  // List of users based on search
        library: {
        id: null,
        name: '',
        description: '',
        email: '',
        numTel: '',
        limitBook:'',
        city: '',
        postalCode:'',
        latitude: '',
        longitude: '',
        address: '',
        fileDBId: null,
        fileBannerDBId: null,
        fileUrl: '',
        fileBannerUrl: '',
        ownerId: null,
      },
      images: [],
      bannerImage:[],
      bannerImageUpload:[],
      imagesUpload:[],
      load: false,
      uploadPercentage: null,
      uploadPercentageBanner: null,
      fakePercentage: null,
      showLoading: false,
      showLoadingBanner: false,
      loading: false,
      valid: false,
      imagesId: "1",
      imagesIdBanner: "0",
      imageIdBanner: null,
      imageId: null,
      search: '',
      noDataText: 'No categories found',
      isEditing: false,
      headers: [
        { text: 'Image', value: 'fileUrl' },
        { text: 'Nom', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Numéro de téléphone', value: 'numTel' },
        { text: 'Addresse', value: 'address' },
        { text: 'Ville', value: 'city' },
        // { text: 'limite livre', value: 'limitBook' },
        { text: 'Naviguer', value: 'detail', sortable: false },
        { text: 'Modifier', value: 'edit', sortable: false },
        { text: 'Supprimer', value: 'delete', sortable: false },
        
      ],
      confirmDeleteDialog: false,
      libraryIdToDelete: null,
    };
  },
  created() {
   
    this.fetchLibraries();
  },
  // async beforeRouteEnter(to, from, next) {
  //   const libraryId = to.params.id;
  //   try {
  //     const response = await axios.get(`${Constant.QORANI}library/library/get/${libraryId}`);
  //     next((vm) => {
  //       vm.library = response.data;
  //       const fileUrl = vm.getImageUrl(response.data.fileDBId) || response.data.fileUrl;
  //       vm.images.push({
  //         name: "",
  //         path: fileUrl,
  //         highlight: 1,
  //         default: 1,
  //       });

  //       const fileBannerUrl =
  //         vm.getImageUrlBanner(response.data.fileBannerDBId) || response.data.fileBannerUrl;

  //       vm.imagesBanner.push({
  //         name: "",
  //         path: fileBannerUrl,
  //         highlight: 1,
  //         default: 1,
  //       });
  //     });
  //   } catch (error) {
  //     console.error("Error fetching product details:", error);
  //     next(null);
  //   }
  // },
  methods: {
    getImageUrl(fileDBId) {
      return `${Constant.QORANI}fileDB/files/${fileDBId}`;
    },
    getImageUrlBanner(fileBannerDBId) {
      return `${Constant.QORANI}fileDB/files/${fileBannerDBId}`;
    },

    searchUsersByEmail() {
      if (this.searchEmail.length >= 3) {
        axios.get(Constant.QORANI+`user/findByKeyword/${this.searchEmail}/AndRole/LIBRARY`)
          .then(response => {
            this.userEmails = response.data;
          })
          .catch(error => {
            console.error("Error searching users:", error);
          });
      } else {
        this.userEmails = [];
      }
    },
    
    selectUser(user) {
  this.library.email = this.searchEmail = user.email;
  this.library.ownerId = user.userId; // Set ownerId from selected user
  this.userEmails = []; // Clear the user emails list
  this.selectedUser = user; // Store selected user information
},
    onCityChange(selectedCityName) {
    // Find the selected city object by its name
    const selectedCity = this.cities.find(city => city.label === selectedCityName);
    
    if (selectedCity) {
      this.selectedCityDetails = {
        label: selectedCity.label,
        latitude: selectedCity.latitude,
        longitude: selectedCity.longitude,
        codepostal: selectedCity.zip_code 
      };

      // Update latitude, longitude and postal code in the library object
      this.library.latitude = selectedCity.latitude;
      this.library.longitude = selectedCity.longitude;
      this.library.postalCode = selectedCity.zip_code;
    }
  },
  onCodePostalChange() {
    const matchingCity = this.cities.find(postalCode => postalCode.zip_code === this.library.postalCode);

    if (matchingCity) {
      this.library.city = matchingCity.label;
      this.library.latitude = matchingCity.latitude;
      this.library.longitude = matchingCity.longitude;
    } else {
      // If no zip code matches, you can clear or reset the city
      this.library.city = '';
      this.library.latitude = '';
      this.library.longitude = '';
    }
  },

    viewLibraryDetails(libraryId) {
        this.$router.push({ name: 'LibraryBooks', params: { id: libraryId } });
      },
    async fetchLibraries() {
      try {
        const response = await axios.get(Constant.QORANI +'library/library/getall');
        this.libraries = response.data;
      } catch (error) {
        console.error('Erreur lors de la récupération des bibliothèques :', error);
      }
    },

    async submitForm() {
      // Trigger validation
  this.$refs.form.validate();

// Check if form is valid
if (!this.valid) {
  console.log("La validation du formulaire a échoué.");
  return;
}
  try {
    this.loading = true;

    // Validate form data before submission
    
    
    let response;
    const imageId = store.state.imageId;
      const imageIdBanner = store.state.imageIdBanner;
    if (this.library.id) {
      const libraryData = {
        name:this.library.name,
        email:this.library.email,
        numTel:this.library.numTel,
        city:this.library.city,
        postalCode:this.library.postalCode,
        latitude:this.library.latitude,
        longitude:this.library.longitude,
        address:this.library.address,
        fileDBId:imageId,
        limitBook:this.library.limitBook,
        fileBannerDBId:imageIdBanner,
        ownerId : localStorage.getItem('ownerId')
        };
        
      // Edit existing library
      response = await axios.post(Constant.QORANI +`library/library/edit/${this.library.id}`, libraryData);
      console.log('Bibliothèque mise à jour avec succès :', response.data);
    } else {
      // Add new library
      const imageId = store.state.imageId;
      const imageIdBanner = store.state.imageIdBanner;
      const userData = {
       
      
        name:this.library.name,
        email:this.library.email,
        numTel:this.library.numTel,
        city:this.library.city,
        postalCode:this.library.postalCode,
        latitude:this.library.latitude,
        longitude:this.library.longitude,
        address:this.library.address,
        fileDBId:imageId,
        limitBook:this.library.limitBook,
        fileBannerDBId:imageIdBanner,
        ownerId : this.library.ownerId
        };
      response = await fetch(Constant.QORANI + "library/library/add", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        });
      //response = await axios.post(Constant.QORANI +'library/library/add', this.library);
      //console.log('Library added successfully:', response.data);
      
      //this.library.id = response.data.id; // assuming API returns ID
    }

    this.$refs.form.reset();
    await this.fetchLibraries(); // Refresh library list
  } catch (error) {
    console.error('Erreur lors de l envoi du formulaire :', error.response ? error.response.data : error);
    alert(`Échec de la soumission : ${error.response?.data?.message || error.message}`);
  } finally {
    this.loading = false;
  }
},

isFormValid() {
  // Check that all required fields are filled correctly
  return (
    this.library.name &&
    (!this.user.email || this.user.email) &&
    (!this.user.email || this.library.numTel) &&
    this.library.latitude &&
    this.library.limitBook &&
    this.library.longitude &&
    this.library.city &&
    this.library.address &&
    this.library.postalCode
  );
},

resetForm() {
  this.library = {
    id: null,
    name: '',
    limitBook:'',
    description: '',
    email: '',
    numTel: '',
    city:null,
    postalCode:null,
    latitude: '',
    longitude: '',
    address: '',
    fileDBId: null,
    fileBannerDBId: null,
    fileUrl: '',
    fileBannerUrl: '',
    ownerId: null,
  }; // Reset form fields
  this.userEmails='';
},
async editLibrary(library) {
  this.library = { ...library};
  this.isEditing = true;
  this.searchEmail = this.library.email;
  // this.images.push({
  //         name: "",
  //         path: library.fileUrl,
  //         highlight: 1,
  //         default: 1,
  //       });
        
  // this.bannerImage.push({
  //         name: "",
  //         path: library.fileBannerUrl,
  //         highlight: 1,
  //         default: 1,
  //       });
  this.images = [{
      name: "",
      path: this.getImageUrl(library.fileDBId),
      highlight: 1,
      default: 1,
    }];
    
    this.bannerImage = [{
      name: "",
      path: this.getImageUrlBanner(library.fileBannerDBId),
      highlight: 1,
      default: 1,
    }];
  this.$nextTick(() => {
    const formElement = this.$refs.form.$el; // Récupérer l'élément DOM du formulaire
    formElement.scrollIntoView({ behavior: 'smooth' }); // Faire défiler en douceur
  });
},
    async deleteLibrary(libraryId) {
      this.libraryIdToDelete = libraryId;
      this.confirmDeleteDialog = true;
    },
    async confirmDelete() {
      try {
        await axios.get(Constant.QORANI +`library/library/delete/${this.libraryIdToDelete}`);
        console.log('Bibliothèque supprimée avec succès !');
        this.fetchLibraries(); // Refresh library list
      } catch (error) {
        console.error(`Erreur lors de la suppression de la bibliothèque ${this.libraryIdToDelete}:`, error);
      } finally {
        this.confirmDeleteDialog = false; // Close the dialog
      }
    },
    cancelDelete() {
      this.confirmDeleteDialog = false; // Close the dialog
    },
    handleImagesUpdated(images) {
      const uploadedImage = images[0];
      console.log("Image téléchargée :", uploadedImage);
    },
    async uploadImageSuccess(formData, index, fileList) {
      try {
        await store.dispatch("uploadImageSuccess", { formData, index, fileList });
        const imageId = store.state.imageId;
        console.log("Image id :", imageId);
      } catch (error) {
        console.error("Failed to upload image:", error);
      }
    },

    beforeRemove(index, done, fileList) {
  console.log('Avant suppression :', index, fileList);
  
  const confirmation = confirm("Voulez-vous vraiment supprimer cette image ?");
  
  if (confirmation) {
    console.log('Suppression confirmée pour l\'index :', index);
    
    
    this.images = [];
    this.library.fileDBId = [];
    this.library.fileUrl = [];
    
    // Émettre l'événement pour mettre à jour le v-model
    this.$emit('input', this.images);
    
    done(); // Appel de done() pour continuer
  } 
},
   
    // convertImagePathToFile(element) {
    //   const byteString = atob(element.path.split(",")[1]);
    //   const ab = new ArrayBuffer(byteString.length);
    //   const ia = new Uint8Array(ab);
    //   for (let i = 0; i < byteString.length; i++) {
    //     ia[i] = byteString.charCodeAt(i);
    //   }
    //   const blob = new Blob([ia], { type: "image/jpeg" });

    //   return new File([blob], element.name);
    // },
    //////////////////////////////////////////////////////////////////
    handleBannerImageUpdated(bannerImage) {
      const uploadedImage = bannerImage[0];
      console.log("Image téléchargée :", uploadedImage);
    },
    async uploadImageSuccessBanner(formData, index, fileList) {
      try {
        await store.dispatch("uploadImageSuccessBanner", { formData, index, fileList });
        const imageIdBanner = store.state.imageIdBanner;
        console.log("Image idBanner :", imageIdBanner);
      } catch (error) {
        console.error("Failed to upload image:", error);
      }
    },
    
    beforeRemoveBanner(index, done, fileList) {
  console.log('Avant suppression :', index, fileList);
  
  const confirmation = confirm("Voulez-vous vraiment supprimer cette image ?");
  
  if (confirmation) {
    console.log('Suppression confirmée pour l\'index :', index);
    this.bannerImage = [];
    this.library.fileBannerDBId = [];
    this.library.fileBannerUrl = [];
    // Émettre l'événement pour mettre à jour le v-model
    this.$emit('input', this.bannerImage);
    
    done(); // Appel de done() pour continuer
  } 
},
    convertImagePathToFile(element) {
      const byteString = atob(element.path.split(",")[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ia], { type: "image/jpeg" });

      return new File([blob], element.name);
    },
  }
};
</script>

<style scoped>
/* Base styles */
.library-form-section {
  padding: 24px;
  background-color: #f4f7f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Card styling for form and data table */
.form-card, .v-card {
  margin-bottom: 20px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff; /* Clean white background */
}

/* Text field styling */
.v-text-field, .v-autocomplete {
  margin-bottom: 16px; /* Spacing between fields */
}

/* Button styling */
.v-btn {
  border-radius: 6px;
 
}

/* Progress bar styling */
.progress-bar {
  margin-top: 10px; /* Space above progress bar */
}

/* Divider styling */
.v-divider {
  margin: 24px 0; /* Increased spacing for dividers */
}

/* Data table styling */
.library-data-table {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for data table */
  border-radius: 12px; /* Rounded corners */
}

.library-data-table thead th {
  background-color: #f4f5f7; /* Light gray for headers */
  color: #333; /* Darker text for contrast */
  font-weight: 500; /* Slightly bold */
  border-bottom: 2px solid #e0e0e0; /* Subtle border */
}

/* Icon styling */
.v-icon {
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}
.icon-edit:hover {
  color: #1565c0; /* Darker blue on hover */
  transform: scale(1.2); /* Slightly larger on hover */
}
.icon-delete:hover {
  color: #b71c1c; /* Darker red on hover */
  transform: scale(1.2); /* Slightly larger on hover */
}
.icon-view:hover {
  color: #1cb74b; /* Darker red on hover */
  transform: scale(1.2); /* Slightly larger on hover */
}

/* Search field styling */
.v-text-field[v-model="search"] {
  max-width: 350px; /* Slightly wider search field */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .library-form-section {
    padding: 16px; /* Reduced padding for smaller screens */
  }

  .v-row {
    flex-direction: column; /* Stack elements vertically */
  }

  .v-col {
    width: 100%; /* Full width for each column on small screens */
  }

  .v-data-table {
    font-size: 14px; /* Smaller font size for tables */
  }

  .v-btn {
    width: 100%; /* Full width buttons */
  }
}

@media (min-width: 769px) {
  .v-col {
    max-width: 45%; /* Allow two items per row on larger screens */
    margin: 0 2.5%; /* Horizontal margin for spacing */
  }

  .v-data-table {
    font-size: 16px; /* Default font size for desktop */
  }
}

/* Loading spinner style */
.v-progress-linear {
  position: relative; /* Allow positioning of inner elements */
}


</style>